import React, {useState} from "react";
import {Button, Dialog, DialogContent} from "@mui/material";
import {TableBar} from "@mui/icons-material";
import Tables from "./Tables";

const TablesDialog = () => {
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleToggleConfirmDialog = () => {
        setOpenConfirmDialog(!openConfirmDialog)
    }
    return <>
        <Button endIcon={<TableBar/>} onClick={handleToggleConfirmDialog}>
        Vedi tavoli
        </Button>
        <Dialog open={openConfirmDialog} onClose={handleToggleConfirmDialog} maxWidth={'lg'}>
            <DialogContent>
                <Tables/>
            </DialogContent>
        </Dialog>
    </>;
}

export default TablesDialog