import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAOr2hBjGVTP1kjPYNkhDrE_ZjMvYJiqzg",
    authDomain: "unito-pe-2023.firebaseapp.com",
    projectId: "unito-pe-2023",
    storageBucket: "unito-pe-2023.appspot.com",
    messagingSenderId: "979408572148",
    appId: "1:805634070664:web:31affc1b1ffcba3f25673d",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}
