import {CssBaseline, ThemeProvider, Typography} from "@mui/material";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import './App.css';
import {customTheme} from "./theme/customTheme";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import Iscrizioni from "./pages/iscritti/Iscrizioni";
import axios from "axios";
import AuthService from "./services/auth.service";
import Home from "./pages/Home";
import Settings from "./pages/settings/Settings";
import {GlobalContext} from "./state/global";
import {useContext, useEffect, useReducer, useState} from "react";
import Logger from "./state/Logger";
import {globalReducer, initializeState} from "./state/global/globalReducer";
import useAxios from "axios-hooks";
import SettingsService from "./services/settings.service";
import CustomLoader from "./components/CustomLoader";
import {setDataStructures, setGlobalSettings} from "./state/global/globalActions";
import UserDetails from "./pages/iscritti/UserDetails";
import DataStructuresService from "./services/dataStructures.service";
import CheckIn from "./pages/CheckIn";
import {auth} from "./firebase/clientApp";

const AppUI = () => {
    document.body.style.backgroundColor = '#FFFFFF';

    const [user, setUser] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if(user) {
                setUser(user)
            } else {
                navigate('/login', { replace: true })
            }
        })
    },[])

    return (<>
            <Routes>
                <Route path={'/checkin'} element={<CheckIn/>}/>
                <Route path={'/login'} element={<Login/>}/>
                {user && <Route element={<ProtectedRoute user={user}/>}>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/iscrizioni'} element={<Iscrizioni/>}/>
                    <Route path={'/iscrizioni/user/:id'} element={<UserDetails/>}/>
                    <Route path={'/settings'} element={<Settings/>}/>
                </Route>}
            </Routes>
    </>)
}

const App = () => {
    const currentLocation = useLocation().pathname
    axios.interceptors.request.use(
        async config => {
            const token = await auth.currentUser?.getIdToken()
            if (token) {
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    );

    return (
        <GlobalContext.Provider
            value={useReducer(Logger(globalReducer), {}, () => initializeState({currentLocation: currentLocation}))}
            displayName="Global Context">
            <ThemeProvider theme={customTheme}>
                <CssBaseline/>
                <AppUI/>
            </ThemeProvider>
        </GlobalContext.Provider>
    );
}

export default App;
