import {Check, Close, ContentCopy, Link, LocalOffer, VideoCameraFront} from "@mui/icons-material";
import React from "react";
import _, {filter, find, map} from 'lodash'
import {fieldTypes} from "./utils";
import axios from "axios";
import {API_URL} from "../config";
import authHeader from "./auth-header";
import {Box, Button, Chip, Stack, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../components/CustomTooltip";
import QrCodeComponent from "../components/QrCodeComponent";

const columnsUrl = (confId) => {
    return `${API_URL}config/columns/${confId||''}`
}

const saveColumsToExport = (confId, columns) => {
    return axios.post(`${columnsUrl()}export`, {[confId]: columns}, )
};

const saveDefaultVisibilityModel = (formId, columns) => {
    return axios.post(`${columnsUrl()}visibility`, {[formId]: {defaultVisibilityModel: columns}}, )
};

const getFieldsFromForm = (form) => {
    let fields = []
    if(form) {
        if (form.sections) {
            form.sections.forEach((section) => {
                fields = fields.concat(map(section.fields, (field) => ({...field, section: section.label})))
            })
            return fields
        } else {
            return form.fields
        }
    }
}

const getColumns = (form) => {
    const cols = filter(getFieldsFromForm(form), function (o) {
        const field = find(fieldTypes, ['type', o.type])
        return !field.onlyUserForm && !field.noDataGrid
    })?.map((field) => {
        let baseField = {
            field: field.id || field,
            headerName: field.headerName || field.label || field.id || (_.startCase(field)),
            flex: 1,
        }

        baseField = field.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return params.row[field.getter]?.[field.id]?._seconds ?
                    new Date(params.row[field.getter]?.[field.id]?._seconds * 1000)
                    : params.row[field.getter]?.[field.id]
            }
        } : {...baseField, valueGetter: (params) => {
                return params.row[field.id]?._seconds ?
                    new Date(params.row[field.id]?._seconds * 1000)
                    : params.row[field.id]
            }}

        const formType = _.find(fieldTypes, ['type', field.type])
        if(!formType.onlyUserForm) {
            switch (formType) {
                case fieldTypes.DATE:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleDateString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.DATE_TIME:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.TIME:
                    return {
                        ...baseField,
                        type: 'dateTime', // TODO: capire
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                                : params.value ?
                                    new Date(params.value).toString() !== 'Invalid Date' ?
                                        new Date(params.value).toLocaleTimeString('it-IT')
                                        : params.value
                                    : "");
                        },
                    }
                case fieldTypes.CHECK_BOX:
                    return {
                        ...baseField,
                        flex: 0.6,
                        valueGetter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì') {
                                return 'si';
                            } else if (params.value === false || params.value === 'no')
                                return 'no';
                            else return params.value || '-'
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no')
                                return 'No';
                            else return 'Non specificato'
                        },
                        renderCell: (params) => (params.value === true || params.value === 'si' || params.value === 'sì')
                            ? <Check sx={{color: 'green'}}/>
                            : (params.value === false || params.value === 'no' || params.value === '')
                                ? <Close color={'disabled'}/>
                                : '-'
                    }
                case fieldTypes.URL:
                    return {
                        ...baseField,
                        renderCell: (params) => params.value ?
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Tooltip title={"Copia link al qr"}>
                                    <IconButton size={'small'} color={'primary'}
                                                onClick={() => navigator.clipboard.writeText(params.value)
                                                    //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))
                                                }>
                                        <ContentCopy fontSize={'small'}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={'Apri link un\'altra finestra'}>
                                    <Button href={params.value}
                                            target={'_blank'}
                                            sx={{backgroundColor: 'transparent'}}
                                            size={'small'}
                                            onClick={(event) => {
                                                event.stopPropagation()
                                            }}>
                                    </Button>
                                </Tooltip>
                            </Box> : ""
                    }
                case fieldTypes.RADIO_GROUP:
                    return {
                        ...baseField,
                        //flex: 0.6,
                        valueGetter: (params) => {
                            if (params.value === 'si') {
                                return 'si';
                            } else if (params.value === 'no')
                                return 'no';
                            else return find(field.values, ['value', params.value])?.label || params.value
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no' || params.value === 'no')
                                return 'No';
                            else return find(field.values, ['value', params.value])?.label || params.value
                        },
                        renderCell: (params) => {
                            return (params.value === true
                                || params.value?.toLowerCase() === 'si' || params.value?.toLowerCase() === 'sì')
                                ? <Stack direction={'row'} spacing={1}><Check sx={{color: 'green'}}/></Stack>
                                : (params.value === false || params.value?.toLowerCase() === 'no')
                                    ? <Stack direction={'row'} spacing={1}><Close color={'disabled'}/></Stack>
                                    : (find(field.values, ['value', params.value])?.label || params.value || '-')
                        }
                    }
                case fieldTypes.SELECT:
                    return {
                        ...baseField,
                        //flex: 0.6,
                        valueFormatter: (params) => {
                            return find(field.values, ['value', params.value])?.label || params.value
                        },
                    }
                /*case fieldTypes.MAILCHIMP_TAGS:
                    return {
                        ...baseField,
                        //flex: 0.6,
                        renderCell: (params) => {
                            return params.row?.mailchimpTags &&
                                params.row?.mailchimpTags?.tags?.map((tag) => (
                                    <Chip label={`${tag.name}`}
                                          color={'primary'}
                                          size={'small'}
                                          sx={{margin: 0.5}}
                                          icon={<LocalOffer/>}
                                    />
                                ))
                        }
                    }*/
                case fieldTypes.ACCESS_TYPE:
                    return {
                        ...baseField,
                        renderCell: (params) => {
                            if(params.row.partecipazione === 'si')
                                return <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                        <CustomTooltip
                                            title={'Apri link in un\'altra finestra'}
                                            children={<Button href={`https://domanipiugiovani.it/live/${params.id}`}
                                                              target={'_blank'}
                                                              size={'small'}
                                                              color={'accent'}
                                                              variant={'outlined'}
                                                              onClick={(event) => event.stopPropagation()}
                                                              endIcon={<VideoCameraFront/>}>
                                                Link
                                            </Button>}/>
                                        <CustomTooltip
                                            title={"Copia link"}
                                            children={<IconButton color={'primary'}
                                                                  onClick={(event) => {
                                                                      event.stopPropagation()
                                                                      navigator.clipboard.writeText(`https://domanipiugiovani.it/live/${params.id}`)
                                                                  }}>
                                                <ContentCopy fontSize={'small'}/>
                                            </IconButton>}/>
                                    </Box>
                        }
                    }
                default:
                    return baseField
            }
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumns,
    saveColumsToExport,
    saveDefaultVisibilityModel,
    getFieldsFromForm
};

export default ColumnsService;


export const commonFields = [
    {
        "id": "nome",
        "label": "Nome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "cognome",
        "label": "Cognome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "email",
        "label": "E-mail",
        "type": "email",
        "precompiled": true,
        "defaultVisible": true
    }
]