import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Alert, AlertTitle, Autocomplete,
    Backdrop, Badge,
    Box,
    Card,
    Chip, CircularProgress, DialogActions, DialogContent, DialogTitle,
    Divider,
    Paper,
    Stack, TextField
} from "@mui/material";
import CheckinAppBar from "./CheckinAppBar";
import Button from "@mui/material/Button";
import {Add, AddCircle, Clear, ExpandMore, FiberManualRecord, Refresh} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import _ from 'lodash'
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import {API_URL} from "../../config";
import {GlobalContext} from "../../state/global";
import useAxios from "axios-hooks";
import UserService from "../../services/user.service";

const Tables = () => {

    const [{data: participants, loading: loadingParticipants, error: errorParticipants}, fetchUsers] = useAxios(
        `${API_URL}participantsFisici`, { method: "GET", useCache: false, manual: false }
    )

    const [{data: checkinsStats, loading: loadingCheckins, error: errorCheckins}, fetchKpis] = useAxios(
        `${API_URL}checkin/stats`, { method: "GET", useCache: false, manual: false }
    )

    const [{data: tables, loading: loadingTables, error: errorTables}, fetchTables] = useAxios(
        `${API_URL}checkin/tables`, { method: "GET", useCache: false, manual: false }
    )

    const checkins = useMemo(() => {
        if(!checkinsStats || !participants)
            return `- / -`
        return `${checkinsStats?.checkin} / ${participants?.length} partecipanti tot`
    }, [checkinsStats, participants])

    const [openDialog, setOpenDialog] = useState({open: false, table: null});
    const [error, setError] = useState(false);

    function handleOpenDialog(event, table) {
        setOpenDialog({open: true, table: table})
    }

    function handleCloseDialog() {
        setOpenDialog({open: false, table: null})
    }

    const checkinUser = async (e, id, table) => {
        setError(false);
        try {
            await axios.get(API_URL + "participantsFisici/" + id + "/checkin/"+table);
            return true;
        } catch (e) {
            if(e.response.status === 409) {
                console.log(e);
                return true;
            } else {
                setError(e);
                return false;
            }
        }
    };

    const resetCheckInUser = async (id) => {
        //await axios.get(API_URL + "user/" + id + "/checkin"); // TODO METTERE API VERA
        await axios.get(API_URL + "participantsFisici/" + id + "/reset") // TODO METTERE API VERA
            .catch(err => setError(err.response))
    };

    return (
        <div>
            <CheckinAppBar goBack mobile={false}/>
            {error &&
                <Alert severity={"error"} sx={{m: 2}}>
                    <AlertTitle>QR Code non valido.</AlertTitle>
                    {error.response.data.error}
                </Alert>}
            <Backdrop open={loadingTables || loadingParticipants} sx={{zIndex: 10000}}><CircularProgress/></Backdrop>
            <Dialog open={openDialog.open} onClose={handleCloseDialog}>
                <DialogTitle>Aggiungi manualmente partecipante al tavolo {openDialog?.table?.number || ''}</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        sx={{mt:1}}
                        renderInput={(params) => <TextField {...params} label="Cerca utente" />}
                        loading={loadingTables}
                        getOptionLabel={(option) => `${option.nome} ${option.cognome} (${option.email}) - ${option.tipologiaDiEnte}`}
                        options={participants || []}
                        get
                        onChange={(event, newValue) => {
                            //manualAddParticipant(newValue).then(r => handleCloseDialog())
                        }}
                        renderOption={(props, option, { selected }) => (
                            <ListItem
                                key={option.id}
                                secondaryAction={
                                    !option.checkin ?
                                        <Button variant={"outlined"}
                                                endIcon={<CheckCircleIcon sx={{ color: 'darkseagreen', fontSize: '2.5rem' }} />}
                                                onClick={(e) => {
                                                    checkinUser(e, option.id, openDialog.table?.number).then(() => {
                                                        fetchTables().then()
                                                        fetchUsers().then()
                                                        fetchKpis().then()
                                                    })
                                                }}>Check-in</Button>
                                        : (option.checkin === true && option.tavolo) ?
                                            <Button variant={"outlined"}
                                                    endIcon={<Clear sx={{ color: 'red', fontSize: '2.5rem' }} />}
                                                    onClick={(e) => {
                                                        resetCheckInUser(option.id).then(() => {
                                                            fetchTables().then()
                                                            fetchUsers().then()
                                                            fetchKpis().then()
                                                        })}
                                                    }>Reset Check-in ({option.tavolo})</Button>
                                            : null
                                }
                            >
                                <ListItemText
                                    primary={option.nome +  ' ' + option.cognome}
                                    secondary={<Stack>
                                        <Typography fontWeight={'bold'}>{option.tipologiaDiEnte}</Typography>
                                        <Typography>{option.email}</Typography>
                                    </Stack>}
                                />
                            </ListItem>
                        )}
                    />
                </DialogContent>
            </Dialog>
            <Stack sx={{px: 5, mt: 4}} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction={"row"} justifyContent={"start"} alignItems={"center"}>
                    <Button variant={"outlined"} size={"medium"} color={"primary"} sx={{mr: 3}}
                            onClick={() => fetchKpis()}>
                        <Refresh/>
                    </Button>
                    <Box>
                        {/*<Typography variant={"h6"}>Registrati: {participants?.length || '-'}</Typography>*/}
                        <Typography variant={"h6"}>Checkin: {checkins}</Typography>
                    </Box>
                </Stack>
                <Button variant={"contained"} onClick={fetchTables}>Aggiorna VISTA</Button>
            </Stack>
                <Box mb={2} px={1} py={2} display={'flex'} flexWrap={"wrap"} justifyContent={'center'} alignItems={'baseline'}>
                    {tables?.map((table, i) =>
                        <Box key={i} m={2} minWidth={'200px'} maxWidth={'250px'} >
                            <Badge key={i} badgeContent={table.participants?.length || 0} color={'primary'}>
                                <Box key={i} px={1.5} pb={1.5} pt={1} component={Card}
                                     sx={{background: table.overBooked === true ? 'darkseagreen' : table.full === true ? 'darkseagreen' : '#26253129'}}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <FiberManualRecord sx={{color: table?.color || 'primary'}}/>
                                        <Typography gutterBottom variant={'h6'} fontWeight={'lighter'}>Tavolo <strong>#{table.id}</strong></Typography>
                                    </Box>
                                    <Divider sx={{mb: 1}}/>
                                    {
                                        participants && table.participants.length !== 0 && table.participants.map((user, i) =>
                                            <Typography key={i} variant={'body2'}>
                                                {_.find(participants, ['id', user])?.nome} {_.find(participants, ['id', user])?.cognome}
                                            </Typography>
                                        )
                                    }
                                    {table.participants.length === 0 &&
                                        <Typography key={i} variant={'body2'}>Nessun partecipante
                                            assegnato</Typography>}
                                    <IconButton size={'small'} onClick={(event) => handleOpenDialog(event, table)}>
                                        <AddCircle fontSize={'small'}/>
                                    </IconButton>
                                    <Divider sx={{my: 1}}/>
                                    <Accordion defaultExpanded={table.full === false}>
                                        <AccordionSummary expandIcon={<ExpandMore/>}>
                                            Vedi dettagli
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                Object.entries(table.parametri).map(([key, value], i) =>
                                                    <Box key={i} display={'flex'} justifyContent={'space-between'}>
                                                        {
                                                            value > 0 ?
                                                                <>
                                                                    <Typography gutterBottom variant={'body2'}>
                                                                        {key}
                                                                    </Typography>
                                                                    <Chip label={value} size={'small'}/>
                                                                </>
                                                                : ""
                                                        }
                                                    </Box>
                                                )
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            </Badge>
                        </Box>
                    )}
            </Box>
        </div>
    );
}

export default Tables